import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpService } from '@shared/services/http.service';
import { ShoppingCart } from "@shared/models/shopping-cart.model";
import { JwtAuthService } from '@shared/services/auth/jwt-auth.service';
import { SessionStoreService } from '@shared/services/session-store.service';
import { CartItem } from '@shared/models/cart-item.model';
import { HttpParams } from "@angular/common/http";
import Rule from "@shared/models/rule.model";

const GET_CART = 'cart';
const ADD_TO_CART = 'cart-item/';
const GUEST_CART = 'cart/guest';
const GUEST_CART_ITEM = 'cart-item/guest/';
const GUEST_GET_CART = 'cart/guest/';
const MERGE_CART = 'cart/merge/';
const GET_CART_RULE = 'cart/rule';
const GUEST_GET_CART_RULE = 'cart/guest/';
const APPLY_COUPON_CODE = 'cart/apply-coupon';

@Injectable({
  providedIn: "root"
})
export class CartService {

    CART = "CART";

    shoppingCart: ShoppingCart;
    public shoppingCartBS: BehaviorSubject<ShoppingCart>;
    public shoppingCart$: Observable<ShoppingCart>;

    cartItemNumBS: BehaviorSubject<number>;
    cartItemNum$: Observable<number>;

    constructor(private httpService: HttpService,
        private jwtAuthService: JwtAuthService,
        private ss: SessionStoreService) {

        this.shoppingCart = this.ss.getItem(this.CART) ? this.ss.getItem(this.CART) : new ShoppingCart();
        this.shoppingCartBS = new BehaviorSubject<ShoppingCart>(this.shoppingCart);
        this.shoppingCart$ = this.shoppingCartBS.asObservable();

        this.cartItemNumBS = new BehaviorSubject<number>(this.shoppingCart?.cartItems?.length);
        this.cartItemNum$ = this.cartItemNumBS.asObservable();
    }

    setCart(cart: ShoppingCart) {
        console.log("setCart::start");
        this.ss.setItem(this.CART, cart);
        this.shoppingCartBS.next(cart);
        this.cartItemNumBS.next(cart.cartItems.length);
    }

    getGuestCartKey() {
        this.shoppingCart = this.ss.getItem(this.CART);
        return this.shoppingCart && this.shoppingCart.key ? this.shoppingCart.key : '';
    }

    clearCart() {
        this.ss.removeitem(this.CART);
        this.cartItemNumBS.next(0);
    }

    addToCart(cartItem): Observable<ShoppingCart> {
        return new Observable(shoppingCart$ => {
            if (this.jwtAuthService.isLoggedIn()) {
                this.memberAddToCart(cartItem).subscribe(data => {

                    this.setCart(data);
                    shoppingCart$.next(data);
                    shoppingCart$.complete();
                })
            } else {
                let key = this.getGuestCartKey();
                if (key !== '') {
                    this.guestAddToCart(key, cartItem).subscribe(data => {

                        this.setCart(data);
                        shoppingCart$.next(data);
                        shoppingCart$.complete();
                    })
                } else {
                    this.guestCreateCart().subscribe(data1 => {
                        this.guestAddToCart(data1.key, cartItem).subscribe(data2 => {

                            this.setCart(data2);
                            shoppingCart$.next(data2);
                            shoppingCart$.complete();
                        })
                    })
                }
            }
        })
    }

    updateCart(cartItem: CartItem): Observable<ShoppingCart> {
        if (this.jwtAuthService.isLoggedIn()) {
            return this.memberUpdateCartItem(cartItem);
        } else {
            let key = this.getGuestCartKey();
            return this.guestUpdateCartItem(key,cartItem);
        }
    }

    updateCartByMethod(cart: ShoppingCart): Observable<ShoppingCart> {
        if (this.jwtAuthService.isLoggedIn()) {
            return this.memberUpdateCart(cart);
        } else {
            let key = this.getGuestCartKey();
            return this.guestUpdateCart(key,cart);
        }
    }

    getCart(): Observable<ShoppingCart> {

        if (this.jwtAuthService.isLoggedIn()) {

            return this.memberGetCart();
        } else {

            let key = this.getGuestCartKey();
            if (key === '') {
                return this.guestCreateCart();
            } else {
                return this.guestGetCart(key);
            }
        }
    }

    mergeGuestCart(): Observable<ShoppingCart> {
        return new Observable((shoppingCart$) => {
            let key = this.getGuestCartKey();
            if (key !== '') {
                this.mergeCart().subscribe(data => {
                    this.setCart(data);
                    shoppingCart$.next(data);
                    shoppingCart$.complete();
                })
            }
        })
    }

    deleteCartItem(cartItem) {
        let key = this.getGuestCartKey();
        console.log(key);
        if (key === '') {
            return this.memberDeleteCartItem(cartItem);
        } else {
            return this.guestDeleteCartItem(key, cartItem);
        }
    }

    getCartItem(cartItemId: number): CartItem {
        this.shoppingCart = this.ss.getItem(this.CART);
        return this.shoppingCart.cartItems.find(c => c.id == cartItemId);
    }

    // for member

    memberGetCart(): Observable<ShoppingCart> {
        return this.httpService.get(GET_CART, null);
    }

    memberAddToCart(cartItem): Observable<any> {
        return this.httpService.post(ADD_TO_CART, { body: cartItem });
    }

    memberUpdateCart(cart) : Observable<any> {
        return this.httpService.put(GET_CART, { body: cart })
    }

    memberUpdateCartItem(cartItem): Observable<any> {
        return this.httpService.put(ADD_TO_CART + cartItem.id, { body: cartItem });
    }

    memberDeleteCartItem(cartItem): Observable<any> {
        return this.httpService.delete(ADD_TO_CART + cartItem.id, null);
    }

    // for guest
    guestCreateCart(): Observable<ShoppingCart> {
        return this.httpService.post(GUEST_CART, {});
    }

    guestAddToCart(key: string, cartItem): Observable<ShoppingCart> {
        return this.httpService.post(GUEST_CART_ITEM + key, { body: cartItem });
    }

    guestGetCart(key: string): Observable<ShoppingCart> {
        return this.httpService.get(GUEST_GET_CART + key, null);
    }

    guestUpdateCart(key: string, cart) : Observable<any> {
        return this.httpService.put(GUEST_GET_CART + key, { body: cart })
    }

    guestUpdateCartItem(key: string, cartItem): Observable<any> {
        return this.httpService.put(GUEST_CART_ITEM + key + '/' + cartItem.id, { body: cartItem });
    }

    guestDeleteCartItem(key: string, cartItem): Observable<any> {
        return this.httpService.delete(GUEST_CART_ITEM + key + '/' + cartItem.id, null);
    }

    mergeCart(): Observable<any> {
        let key = this.getGuestCartKey();
        return this.httpService.post(MERGE_CART + key, {});
    }

    getCartRule(): Observable<any> {
        console.log("enter get");
        
        if (this.jwtAuthService.isLoggedIn()) {
            return this.memberGetCartRule();
        } else {
            let key = this.getGuestCartKey();
            return this.guestGetCartRule(key);
        }
    }

    applyCouponCode(couponCode: string, cart: any) {
        let key = this.getGuestCartKey();
        const params = new HttpParams().set('cartId', cart.id)
            .set('couponCode', couponCode)
        return this.httpService.put(APPLY_COUPON_CODE + key, { params: params});
    }

    setCouponCode(couponCode: string): any {
        const req = this.ss.getItem(this.CART);
        req.couponCode = couponCode
        if (couponCode) {
            this.updateCouponCode(req).subscribe(response =>{
                console.log("enter update");
                
                if(couponCode && (!response || !response.couponCode)) {
                    response.isCouponInvalid = true
                }else {
                    response.isCouponInvalid = false
                }
                console.log("responseU::", response);
                console.log("req::", req);
                
                this.setCart(response)
                console.log("before get");
                this.getCartRule().subscribe(res => {
                    console.log("updateRES", res);
                    
                    return res;
                })
            }, error => {
                req.isCouponInvalid = true
                this.setCart(req)
                console.log("before get");
                this.getCartRule().subscribe(res => {
                    return res;
                })
            })
        }
        
        //return of(rules);
    }

    memberGetCartRule(): Observable<any> {
        return this.httpService.get(GET_CART_RULE, null);
    }

    guestGetCartRule(key: string): Observable<any> {
        return this.httpService.get(GUEST_GET_CART_RULE + key + '/rule', null);
    }

    updateCouponCode(cart): Observable<ShoppingCart> {
        if (this.jwtAuthService.isLoggedIn()) {
            return this.memberUpdateCart(cart);
        } else {
            let key = this.getGuestCartKey();
            return this.guestUpdateCart(key,cart);
        }
    }
}
